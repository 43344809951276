<template>
    <v-container id="contactUs" class="pt-8 pb-8 pa-0" flat>        
        <BannerPage icon="mdi-email-send " :title='$t("message.contacts_header")'   />
        <v-skeleton-loader tile type="article, article" :loading="contactPageLoading">
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>                    
            </v-overlay>
            <v-row>
                <v-col cols="12" :md="getContactsValue('contacts_email_from_user_presence') ? 6 : 12">  
                    <v-card flat tile>
                        <v-card-text>
                            <v-row class="contactsUsRowTitle headline">
                                <span class="contactsUsTitle" v-html='$t("message.contacts_header")'></span>
                            </v-row>
                            <v-row class="contactsUsRowSubtitle">
                                <span class="contactsUsSubtitle" v-html='$t("message.contacts_description")'></span>
                            </v-row>
                            <v-row class="contactsUsRowText">
                                <span class="contactsUsText" v-html='$t("message.contacts_text")'></span>
                            </v-row>
                            <template v-for="(field, i) in fields">
                                <v-row v-if="getContactsValue('contacts_' + field + '_presence') == 1" 
                                    :key="'contactRowInfo-' + i" 
                                    class="contactsUsRowInfo">
                                    <v-img v-if="getContactsValue('contacts_' + field + '_icon') == 1" 
                                        :src="getImage(field)" 
                                        class="contactsUsInfoIcon"></v-img>
                                    <span v-if='$t("message.contacts_" + field + "_label") != ""' 
                                        v-html='$t("message.contacts_" + field + "_label")'
                                        class="contactsUsInfoLabel"></span>
                                    <span v-if='$t("message.contacts_" + field + "_text") != ""'
                                        v-html='$t("message.contacts_" + field + "_text")' 
                                        class="contactsUsInfoText"></span>
                                </v-row>
                            </template>
                            <v-row v-if='$t("message.contacts_bottom_text") != ""' class="contactsUsRowBottomText">
                                <p class="contactsUsBottomText" v-html='$t("message.contacts_bottom_text")'></p>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col v-if="getContactsValue('contacts_email_from_user_presence')" cols="12" md="6">
                    <v-container class="border contactsUsSendEmailContainer">
                        <v-card flat tile >
                            <v-card-title class="contactsUsSendEmailTitle">{{ $t("message.contacts_email_from_user_description") }}</v-card-title>
                            <v-card-text>
                                <v-form ref="emailForm" class="contactsUsSendEmailForm" v-model="isValid">
                                    <v-textarea v-model="textMail" :rules="textMailRules" autocomplete="email" :label='$t("message.contacts_email_text")' class="contactsUsSendEmailText"></v-textarea>
                                    <v-file-input v-model="files"
                                        multiple :label='$t("message.contacts_email_attachment_text")'
                                        class="contactsUsSendEmailFiles"
                                        @change="changeFiles"
                                        @click:clear="allFiles = []">
                                    </v-file-input>
                                    <div v-if="files.length">
                                        <h5>Files: #{{counter}}, size:{{size}}</h5>
                                        <v-chip close v-for="(file, i) in allFiles" class="ma-1" :key=i 
                                            @click:close="deleteFile(i, file.name)">
                                            {{ file.name }}
                                        </v-chip>
                                    </div>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn class="contactsUsSendEmailButton primaryColorBg white--text" 
                                :disabled="!isValid"
                                @click="onClickSendEmail">{{ $t("message.contacts_email_send_button") }} </v-btn>
                            </v-card-actions>  
                        </v-card>
                    </v-container>
                </v-col>
            </v-row>
        </v-skeleton-loader>
        <Snackbar ref="mySnackbar"></Snackbar> 
    </v-container>
</template>

<script>
import BannerPage from './BannerPage.vue';
import Snackbar from './Snackbar.vue';
import { services } from '../scripts/services/serviceBuilder'

export default {
    name: 'contactUs',
    data: function() {
        return {    
            contacts: null,
            email: '',
            contactPageLoading: true,
            fields: ['phone', 'fax', 'email', 'address', 'pec'],
            files: [],
            allFiles: [],
            base64Files: [],
            isValid: true,
            textMail: '',
            textMailRules: [this.checkRequired],
            overlay: false
        }
    },
    props: {
        config: null
    },
    computed: {
        counter: function() {
            return this.allFiles.length;
        },
        size: function() {
            var bytes = this.allFiles.map(x => x.size).reduce((a, b) => a + b, 0);
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) 
                return bytes;
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return parseFloat(bytes / Math.pow(1024, i), 2).toPrecision(3) + ' ' + sizes[i];
        }
    },
    components: {
        BannerPage,
        Snackbar
    },
    methods: {
        changeFiles: function(files) {
            files.forEach(file => {
                if (!this.allFiles.some(x => x.name == file.name)) {
                    this.allFiles.push(file);
                }
            })
            this.files = this.allFiles;
        },
        checkRequired: function (value) {
            if (value == null || value.toString().length == 0)
                return this.$t("message.contacts_text_needed");
            return true;
        },
        deleteFile: function(i) {
            this.allFiles.splice(i,1);
            this.files = this.allFiles;
        },
		getContacts: function () {
			var that = this;
			services.apiCallerContact.data()
				.then(response => {
					that.contacts = response.data;
					that.contactPageLoading = false;
				})
				.catch(error => {
					console.log(error);
				});	
		},
        getContactsValue: function (settingName) {
            if(!this.contacts)
                return null;
            return this.contacts[settingName];
        },
        getImage: function (field) {
			return './' + this.config.customerName + '/images/contacts_' + field + '.png';
		},
        onClickSendEmail: function () {
            var that = this;
            that.overlay = true;

            var obj = {
                'body' : that.textMail,
                'attachments' : []
            };
            var convertions = []
            for(var i = 0; i < that.allFiles.length; i++) {
                convertions.push(this.getBase64(that.allFiles[i]));
            }

            Promise.all(convertions)
                .then(responses => {
                    for(var i = 0; i < responses.length; i++) {
                        var base64 = responses[i];
                        var att = {
                            'name' : that.allFiles[i].name,
                            'mime' : that.allFiles[i].type,
                            'base64' : base64
                        };
                        obj.attachments.push(att);
                    } 
                    
                    var success = null;
                    var message = this.$t("message.contacts_email_error");
                    services.apiCallerContact.sendEmail(that.$cod_client.value, obj)
                        .then(res => {
                            if(res.data != null) {
                                success = res.data.success ? 'success' : null;
                                message = res.data.message;
                            }                            
                        })
                        .catch(error => {
                            console.log(error);
                        })
                        .finally(() => {
                            that.$refs.emailForm.reset();
                            that.allFiles = [];
                            that.overlay = false;
                            that.$refs.mySnackbar.open(message, success, 3000);
                        });
                })
                .catch(error => {
                    console.log(error);
                    that.overlay = false;
                })
        },
        getBase64: function(file) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }
    },
	created: function () {
		this.getContacts();
	}
}
</script>
